html {
  height: 100%;
  width: 100%;
  margin: 0;
}

body {
  background-color: #f2f5f8;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100%;
  height: 100%;
  background-color: #f2f5f8;
}

.occu-loader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: #4f46e5;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: occu-loader-spinner 1s linear infinite;
}
@keyframes occu-loader-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
